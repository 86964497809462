import httpclient from "../../shared/auth/http-client";

const initialState = {
  hostsLoading: false,
  hosts: [],
  hostMetricsLoading: false,
  hostMetrics: {
    result: [],
  },
  hostLogsLoading: false,
  hostLogs: {
    result: [],
  },
  HostConnectionsLoading: false,
  hostConnections: {
    result: [],
  },
};

const pool = "BellWVDPool2";
const state = { ...initialState };

const actions = {
  async getHostsAsync({ commit }, update = false) {
    if (update) {
      try {
        commit("setHostsLoading", true);
        const { data } = await httpclient.get(`pools/${pool}/hosts`);
        commit("setHosts", data);
      } catch (e) {
        console.error("VUEX hosts/getHostsAsync", e);
      } finally {
        commit("setHostsLoading", false);
      }
    }
  },
  async getHostMetricsAsync(
    { commit },
    { hostName, update = false, start, end }
  ) {
    if (update) {
      try {
        commit("setHostMetrics", []);
        commit("setHostMetricsLoading", true);
        const { data } = await httpclient.get(
          `pools/${pool}/hosts/${hostName}/metrics?filterFrom=${start}&filterTo=${end}`
        );
        commit("setHostMetrics", data);
      } catch (e) {
        console.log("VUEX hosts/getHostMetrics", e);
      } finally {
        commit("setHostMetricsLoading", false);
      }
    }
  },
  async getHostLogsAsync({ commit }, { hostName, update = false, start, end }) {
    if (update) {
      try {
        commit("setHostLogs", []);
        commit("setHostLogsLoading", true);
        const { data } = await httpclient.get(
          `pools/${pool}/hosts/${hostName}/logs?filterFrom=${start}&filterTo=${end}`
        );
        commit("setHostLogs", data);
      } catch (e) {
        console.log("VUEX hosts/getHostLogsAsync", e);
      } finally {
        commit("setHostLogsLoading", false);
      }
    }
  },
  async getHostConnectionsAsync(
    { commit },
    { hostName, start, end, update = false }
  ) {
    if (update) {
      try {
        commit("setHostConnectionsLoading", true);
        const { data } = await httpclient.get(
          `pools/${pool}/hosts/${hostName}/userlogs/aggregated?filterFrom=${start}&filterTo=${end}`
        );
        commit("setHostConnections", data);
      } catch (e) {
        console.log("VUEX hosts/getHostLogsAsync", e);
      } finally {
        commit("setHostConnectionsLoading", false);
      }
    }
  },
  resetHostState({ commit }) {
    console.log("Reseting state");
    commit("setInitialState");
  },
};

const mutations = {
  setHostsLoading: (state, loading) => (state.hostsLoading = loading),
  setHosts: (state, hosts) => (state.hosts = hosts),
  setHostMetricsLoading: (state, loading) =>
    (state.hostMetricsLoading = loading),
  setHostMetrics: (state, metrics) => (state.hostMetrics = metrics),
  setHostLogsLoading: (state, loading) => (state.hostLogsLoading = loading),
  setHostLogs: (state, logs) => (state.hostLogs = logs),
  setHostConnectionsLoading: (state, loading) =>
    (state.HostConnectionsLoading = loading),
  setHostConnections: (state, connections) =>
    (state.hostConnections = connections),
  setInitialState: (state) => Object.assign(state, initialState),
};

const getters = {
  hosts: (state) => {
    return state.hosts.map((s) => ({
      name: s.name.split("/")[1].split(".")[0],
      lastHeartBeat: s.properties.lastHeartBeat,
      sessions: s.properties.sessions,
      allowNewSession: s.properties.allowNewSession,
      status: s.properties.status,
    }));
  },
  hostMetrics: (state) => {
    return state.hostMetrics.result;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
