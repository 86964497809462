<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-tabs v-model="tab">
          <v-tab v-for="tab in tabs" :key="tab.id" :to="tab.route" exact>
            {{ tab.name }}</v-tab
          >
        </v-tabs>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <router-view :hostName="hostName"></router-view>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["hostName"],
  beforeRouteLeave(to, from, next) {
    this.resetHostState();
    next();
  },
  data() {
    return {
      tab: `/hosts/${this.hostName}/metrics`,
      tabs: [
        {
          id: 1,
          name: "Metrics",
          route: `/hosts/${this.hostName}/metrics`,
        },
        {
          id: 2,
          name: "Logs",
          route: `/hosts/${this.hostName}/logs`,
        },
      ],
    };
  },
  methods: {
    ...mapActions("hosts", ["resetHostState"]),
  },
};
</script>
