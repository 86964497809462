import httpclient from "../../shared/auth/http-client";

const initialState = {
  usersLoading: false,
  users: {
    result: [],
  },
};

const pool = "BellWVDPool2";
const state = { ...initialState };

const actions = {
  async getUsersAsync({ commit }, update = false) {
    if (update) {
      try {
        commit("setUsersLoading", true);
        const { data } = await httpclient.get(`pools/${pool}/sessions`);
        commit("setUsers", data);
      } catch (e) {
        console.error("sessionHostUsers.js", e);
      } finally {
        commit("setUsersLoading", false);
      }
    }
  },
};

const mutations = {
  setUsersLoading: (state, loading) => (state.usersLoading = loading),
  setUsers: (state, users) => (state.users = users),
};

const getters = {
  users: (state) => {
    return state.users.result.map((u) => {
      //const host = u.host.split("/");

      return {
        host: u.host,
        userPrincipalName: u.userPrincipalName,
        state: u.sessionState,
        sessionNumber: u.sessionNumber,
        sessionCreateTime: u.sessionCreateTime,
      };
    });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
