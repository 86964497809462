<template>
  <div>
    <v-row dense align="center">
      <v-col cols="12" md="3" sm="6" xs="12">
        <v-menu
          ref="rangeStartMenu"
          v-model="rangeStartMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              hide-details
              dense
              outlined
              v-model="rangeStartFormatted"
              persistent-hint
              v-bind="attrs"
              v-on="on"
            >
              <template v-slot:prepend>
                <span class="pt-1">Start</span>
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model="rangeStart.date"
            no-title
            @input="rangeStartMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="3" sm="6" xs="12">
        <v-select
          v-model="rangeStart.time"
          hide-details
          outlined
          :items="timeSelection"
          dense
        >
        </v-select>
      </v-col>
      <v-col cols="12" md="3" sm="6" xs="12">
        <v-menu
          ref="rangeEndMenu"
          v-model="rangeEndMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              hide-details
              dense
              outlined
              v-model="rangeEndFormatted"
              persistent-hint
              v-bind="attrs"
              v-on="on"
            >
              <template v-slot:prepend>
                <span class="pt-1">End</span>
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model="rangeEnd.date"
            no-title
            @input="rangeEndMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="3" sm="6" xs="12">
        <v-select
          v-model="rangeEnd.time"
          hide-details
          outlined
          :items="timeSelection"
          dense
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <div class="caption">
          Note. There are currently no contraints or optimization on filtering.
          Selecting range that is greater or equal to 12-24 hours might cause
          app to crash.
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    start: {
      type: String,
      require: true,
    },
    end: {
      type: String,
      require: false,
    },
  },
  data() {
    return {
      rangeStart: {
        date: undefined,
        time: undefined,
      },
      rangeStartMenu: false,
      rangeEnd: {
        date: undefined,
        time: undefined,
      },
      rangeEndMenu: false,
    };
  },
  created() {
    this.rangeStart.date = moment(this.start)
      .startOf("day")
      .format();
    this.rangeStart.time = moment(this.start).format("hh:00 A");

    this.rangeEnd.date = moment(this.end)
      .startOf("day")
      .format();
    this.rangeEnd.time = moment(this.end).format("hh:00 A");
  },
  watch: {
    rangeStart: {
      deep: true,
      handler() {
        if (this.rangeStart.date && this.rangeStart.time) {
          this.$emit("startChange", this.getRangeStartFormatted().format());
        }
      },
    },
    rangeEnd: {
      deep: true,
      handler() {
        if (this.rangeEnd.date && this.rangeEnd.time) {
          this.$emit("endChange", this.getRangeEndFormatted().format());
        }
      },
    },
  },
  methods: {
    getRangeStartFormatted() {
      return moment(this.rangeStart.date)
        .utc()
        .add(moment(this.rangeStart.time, "h:mm:ss A").hours(), "hours");
    },
    getRangeEndFormatted() {
      return moment(this.rangeEnd.date)
        .utc()
        .add(moment(this.rangeEnd.time, "h:mm:ss A").hours(), "hours");
    },
    // applyRange() {
    //   const rangeStart = getRangeStartFormatted();
    //   const rangeEnd = getRangeEndFormatted();

    //   const difference = rangeStart.diff(rangeEnd, "hours");
    //   if (Math.abs(difference) > 24) {
    //     alert(
    //       "Currently only supports range that is less than or equal to 24 hours"
    //     );
    //     return;
    //   }

    //   this.$emit("applyRange", {
    //     rangeStart: rangeStart.format(),
    //     rangeEnd: rangeEnd.format(),
    //   });
    // },
  },
  computed: {
    rangeStartFormatted() {
      return moment(this.rangeStart.date).format("MM-DD-YYYY");
    },
    rangeEndFormatted() {
      return moment(this.rangeEnd.date).format("MM-DD-YYYY");
    },
    timeSelection() {
      let times = [];
      for (let i = 0; i < 24; i++) {
        times.push(
          moment()
            .utc()
            .startOf("day")
            .hours(i)
            .format("hh:00 A")
        );
      }
      return times;
    },
  },
};
</script>
