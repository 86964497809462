<template>
  <v-app>
    <v-main v-if="$MSAL.getAccount()">
      <v-app-bar app flat>
        <v-container class="py-0 fill-height">
          <v-btn text to="/hosts">Hosts</v-btn>
          <v-btn text to="/users">Users</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="$MSAL.logout()">
            <v-icon>mdi-logout-variant</v-icon>
            <span>Logout</span>
          </v-btn>
        </v-container>
      </v-app-bar>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-main>
      <v-container fluid fill-height v-if="!$MSAL.getAccount()">
        <v-row justify="center" align="center">
          <v-col cols="12" md="3">
            <v-img
              src="./assets/vd-logo.png"
              class="mx-auto"
              max-width="390"
              :contain="true"
              alt="Azure Virtual Desktop"
            ></v-img>
            <v-btn
              @click="login()"
              large
              block
              outlined
              tile
              text
              class="mt-2 light-blue darken-3"
            >
              <v-icon large class="">mdi-login-variant</v-icon>
              <span>Login</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      links: ["SessionHosts", "users"],
    };
  },
  created() {
    this.$vuetify.theme.dark = true;
  },
  methods: {
    async login() {
      await this.$MSAL.login();
      // TEMPORARY FIX
      // EXTREMELY BAD APPROACH https://michaelnthiessen.com/force-re-render/
      this.$forceUpdate();
    },
  },
};
</script>
