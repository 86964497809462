<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
        disable-pagination
        :items-per-page="100"
        hide-default-footer
        :items="users"
        :headers="usersHeaders"
        :loading="usersLoading"
        loading-text=""
        :search="search"
      >
        <template v-slot:top>
          <v-row dense align="end">
            <v-col cols="12" md="1" sm="3">
              <v-btn tile class="primary" @click="getUsers(true)">
                <v-icon> mdi-refresh </v-icon>
                <span>Refresh</span></v-btn
              >
            </v-col>
            <v-col cols="12" md="11" sm="9">
              <v-text-field
                label="Search"
                v-model="search"
                hide-details
                single-line
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-slot:progress>
          <div style="height: 200px; position: relative; margin-top: 4em">
            <LoadingOverlay
              :absolute="true"
              :loading="usersLoading"
              :loadingText="'Loading session users...'"
            ></LoadingOverlay>
          </div>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>
<script>
import LoadingOverlay from "../../components/loading-overlay.vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  data() {
    return {
      usersHeaders: [
        {
          text: "Session host",
          value: "host",
        },
        {
          text: "User",
          value: "userPrincipalName",
        },
        {
          text: "Status",
          value: "state",
        },
        {
          text: "Session Number",
          value: "sessionNumber",
        },
      ],
      search: "",
    };
  },
  async created() {
    if (!this.users.length > 0) {
      await this.getUsers(true);
    }
  },
  methods: {
    ...mapActions("users", ["getUsersAsync"]),
    async getUsers(update = false) {
      await this.getUsersAsync(update);
    },
    filter(value, search, item) {
      console.log(value, search, item);
      return "abc";
    },
  },
  computed: {
    ...mapGetters("users", ["users"]),
    ...mapState("users", ["usersLoading"]),
  },
  components: { LoadingOverlay },
};
</script>
