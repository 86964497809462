<template>
  <v-overlay :absolute="absolute" v-show="loading">
    <div class="text-center">
      <v-progress-circular
        :size="size"
        :width="width"
        :color="color"
        indeterminate
      >
        <span class="caption">{{ loadingText }}</span>
      </v-progress-circular>
    </div>
  </v-overlay>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    absolute: {
      required: false,
      default: false,
    },
    loadingText: {
      type: String,
      default: "Loading...",
    },
    size: {
      type: Number,
      required: false,
      default: 200,
    },
    width: {
      type: Number,
      required: false,
      default: 15,
    },
    color: {
      type: String,
      required: false,
      default: "primary",
    },
  },
};
</script>
