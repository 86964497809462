import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import hostList from "../views/Hosts/host-list.vue";

import HostDetailsRoot from "../views/Hosts/host-details/host-details-root.vue";
import HostDetailsLogs from "../views/Hosts/host-details/host-details-logs.vue";
import HostDetailsMetrics from "../views/Hosts/host-details/host-details-metrics.vue";

import UserList from "../views/Users/user-list.vue";

const routes = [
  {
    path: "/",
    redirect: "/hosts",
  },
  {
    path: "/hosts",
    name: "hosts",
    component: hostList,
  },
  {
    path: "/hosts/:hostName",
    props: true,
    component: HostDetailsRoot,
    children: [
      {
        path: "",
        redirect: "metrics",
      },
      {
        path: "metrics",
        component: HostDetailsMetrics,
      },
      {
        path: "logs",
        component: HostDetailsLogs,
      },
    ],
  },
  {
    path: "/users",
    name: "UserList",
    component: UserList,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
