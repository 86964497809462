import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import MSAL from "./shared/auth/auth";

const msal = new MSAL();
Vue.use(msal);
Vue.prototype.$MSAL = msal;

Vue.config.productionTip = false;

import "./filters/datetime-filter";

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
