<template>
  <div>
    <v-row class="">
      <v-col cols="12" md="12" class="grey darken-4">
        <v-row dense align="end">
          <v-col cols="12" md="10" lg="7" sm="12">
            <RangePicker
              :start.sync="start"
              :end.sync="end"
              @startChange="(s) => (start = s)"
              @endChange="(e) => (end = e)"
            ></RangePicker>
          </v-col>
          <v-col cols="12" md="1" sm="12">
            <v-btn
              block
              tile
              class="primary"
              @click="loadHostMetricsAsync(true)"
            >
              <v-icon>
                mdi-refresh
              </v-icon>
              <span>Refresh</span></v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="grey darken-4">
        <div v-if="!hostMetricsLoading">
          <apexchart
            height="650"
            :options="chartOptions"
            :series="metricData"
          ></apexchart>
        </div>
        <div v-else>
          <div style="height:200px;position:relative;margin-top:4em;">
            <LoadingOverlay
              :absolute="true"
              :loading="hostMetricsLoading"
              :loadingText="'Loading logs...'"
            ></LoadingOverlay>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RangePicker from "@/components/datetime-range-picker.vue";
import LoadingOverlay from "@/components/loading-overlay.vue";
import moment from "moment";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  props: ["hostName"],
  data() {
    return {
      chartOptions: {
        chart: {
          animations: {
            enabled: false,
          },
          background: "transparent",
          foreColor: "#ffffff",
          type: "area",
        },
        stroke: {
          curve: "straight",
          width: 2.5,
        },
        fill: {
          type: "solid",
          opacity: 0.25,
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          show: true,
          borderColor: "#fff",
          strokeDashArray: 1,
          position: "back",
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        markers: {
          size: 1,
          hover: {
            sizeOffset: 5,
          },
          colors: ["#2196f3"],
        },
        noData: {
          align: "center",
          verticalAlign: "middle",
          style: {
            color: "#2196f3",
            fontSize: "36px",
          },
        },
        theme: {
          mode: "dark",
        },
        tooltip: {
          enabled: false,
        },
        xaxis: {
          type: "datetime",
          labels: {
            // eslint-disable-next-line
            formatter: function(value, timestamp, opts) {
              return moment(timestamp).format("HH:mm:ss A");
            },
          },
          tickAmount: 10,
        },
        yaxis: {
          tickAmount: 10,
          labels: {
            // formatter: (value) => parseFloat(value).toFixed(3),
          },
          min: function() {
            return 0;
          },
          max: function(max) {
            return max + 5;
          },
        },
        legend: {
          position: "top",
          fontSize: "18px",
        },
      },
      start: moment()
        .startOf("hour")
        .subtract(6, "hour")
        .format(),
      end: moment()
        .startOf("hour")
        .add(1, "hour")
        .format(),
    };
  },
  created() {
    if (!this.hostMetrics.length > 0) {
      this.loadHostMetricsAsync(true);
    }
  },
  methods: {
    ...mapActions("hosts", ["getHostMetricsAsync"]),
    async loadHostMetricsAsync(update = false) {
      await this.getHostMetricsAsync({
        hostName: this.hostName,
        update,
        start: new Date(this.start).toISOString(),
        end: new Date(this.end).toISOString(),
      });
    },
  },
  computed: {
    ...mapState("hosts", ["hostMetricsLoading"]),
    ...mapGetters("hosts", ["hostMetrics"]),
    metricData() {
      let groupedMetrics = this.hostMetrics.reduce(function(rv, x) {
        (rv[x["metricName"]] = rv[x["metricName"]] || []).push(x);
        return rv;
      }, []);
      groupedMetrics = Object.keys(groupedMetrics).map((x) => ({
        name: x,
        data: groupedMetrics[x].map((y) => {
          return {
            x: new Date(y.timestamp).getTime(),
            y: y.avg,
          };
        }),
      }));

      console.log(JSON.parse(JSON.stringify(groupedMetrics)));

      return groupedMetrics;
    },
  },
  components: {
    LoadingOverlay,
    RangePicker,
  },
};
</script>
