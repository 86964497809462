<template>
  <div>
    <v-row dense align="end">
      <v-col cols="12">
        <div class="px-2 grey darken-4">
          <v-row dense>
            <v-col cols="12" md="10" sm="12">
              <RangePicker
                :start.sync="start"
                :end.sync="end"
                @startChange="(s) => (start = s)"
                @endChange="(e) => (end = e)"
              />
            </v-col>
            <v-col cols="12" md="2" sm="12">
              <v-btn
                :disabled="hostConnectionsLoading"
                tile
                block
                class="primary"
                @click="
                  loadLogs(true);
                  loadConnections(true);
                "
              >
                <v-icon>
                  mdi-refresh
                </v-icon>
                <span>Refresh</span></v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="px-2 grey darken-4">
          <apexchart
            height="275"
            :options="chartOptions"
            :series="users"
          ></apexchart>
          <!-- <div style="height:200px;position:relative;margin-top:4em;">
            <LoadingOverlay
              :absolute="true"
              :loading="hostConnectionsLoading"
              :loadingText="'Loading connections...'"
            ></LoadingOverlay>
          </div> -->
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          disable-filtering
          disable-sort
          :items-per-page="100"
          :items="hostLogs.result"
          :headers="logHeaders"
          @click:row="viewRowDetails"
          :loading="hostLogsLoading"
          loading-text=""
        >
          <template v-slot:item.timestamp="{ item }">
            {{ item.timestamp | formatDate }}
          </template>
          <template v-slot:progress>
            <div style="height:200px;position:relative;margin-top:4em;">
              <LoadingOverlay
                :absolute="true"
                :loading="hostLogsLoading"
                :loadingText="'Loading logs...'"
              ></LoadingOverlay>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      v-model="rowDetails"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="rowDetails = false">
          <v-icon large>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title
          >{{ row.timestamp | formatDate }} Host {{ row.sessionHostName }} log
          details</v-toolbar-title
        >
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-row class="px-2 pt-4">
            <v-col cols="12" md="4" sm="12">
              <span class="text-h4">Sessions</span>
              <v-divider></v-divider>
              <v-simple-table dark>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        User
                      </th>
                      <th class="text-left">
                        Sate
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in row.users"
                      :key="item.properties.userPrincipalName"
                    >
                      <td>{{ item.properties.userPrincipalName }}</td>
                      <td>{{ item.properties.sessionState }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" md="8" sm="12">
              <span class="text-h4">Properties</span>
              <v-divider></v-divider>
              <div class="mx-1 black">
                <vue-json-pretty :showLine="false" :data="row.properties">
                </vue-json-pretty>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RangePicker from "@/components/datetime-range-picker.vue";
import LoadingOverlay from "@/components/loading-overlay.vue";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import { mapActions, mapState } from "vuex";
import moment from "moment";

export default {
  props: ["hostName"],
  data() {
    return {
      rowDetails: false,
      row: { users: [], properties: {} },
      logHeaders: [
        {
          text: "Timestamp",
          value: "timestamp",
        },
        {
          text: "Status",
          value: "status",
        },
      ],
      chartOptions: {
        chart: {
          animations: {
            enabled: false,
          },
          background: "transparent",
          foreColor: "#ffffff",
          type: "area",
        },
        stroke: {
          curve: "straight",
          width: 2.5,
        },
        fill: {
          type: "solid",
          opacity: 0.25,
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          show: true,
          borderColor: "#fff",
          strokeDashArray: 1,
          position: "back",
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        markers: {
          size: 1,
          hover: {
            sizeOffset: 5,
          },
          colors: ["#2196f3"],
        },
        noData: {
          align: "center",
          verticalAlign: "middle",
          style: {
            color: "#2196f3",
            fontSize: "36px",
          },
        },
        theme: {
          mode: "dark",
        },
        tooltip: {
          enabled: false,
        },
        xaxis: {
          type: "datetime",
          labels: {
            // eslint-disable-next-line
            formatter: function(value, timestamp, opts) {
              return moment(timestamp).format("HH:mm:ss A");
            },
          },
          tickAmount: 10,
        },
        yaxis: {
          tickAmount: 10,
          labels: {
            // formatter: (value) => parseFloat(value).toFixed(3),
          },
          min: function() {
            return 0;
          },
          max: function(max) {
            return max + 5;
          },
        },
        legend: {
          position: "top",
          fontSize: "18px",
        },
      },
      start: moment()
        .startOf("hour")
        .subtract(1, "hour")
        .format(),
      end: moment()
        .startOf("hour")
        .add(1, "hour")
        .format(),
    };
  },
  created() {
    this.loadLogs(true);
    this.loadConnections();
  },
  methods: {
    ...mapActions("hosts", ["getHostLogsAsync", "getHostConnectionsAsync"]),
    async loadLogs(update = false) {
      await this.getHostLogsAsync({
        hostName: this.hostName,
        update,
        start: new Date(this.start).toISOString(),
        end: new Date(this.end).toISOString(),
      });
    },
    async loadConnections() {
      await this.getHostConnectionsAsync({
        hostName: this.hostName,
        update: true,
        start: new Date(this.start).toISOString(),
        end: new Date(this.end).toISOString(),
      });
    },
    viewRowDetails(row) {
      this.rowDetails = true;
      this.row = row;
    },
  },
  computed: {
    ...mapState("hosts", [
      "hostLogs",
      "hostLogsLoading",
      "hostConnections",
      "hostConnectionsLoading",
    ]),
    users() {
      return [
        {
          name: "Active sessions",
          data: this.hostConnections.result.map((x) => ({
            x: new Date(x.timestamp),
            y: x.activeSessions,
          })),
        },
        {
          name: "Disconnected sessions",
          data: this.hostConnections.result.map((x) => ({
            x: new Date(x.timestamp),
            y: x.disconnectedSessions,
          })),
        },
      ];
    },
  },
  components: {
    VueJsonPretty,
    LoadingOverlay,
    RangePicker,
  },
};
</script>

<style>
.vjs-tree {
  height: 700px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.vjs-tree__node.is-highlight,
.vjs-tree__node:hover {
  background-color: #424242;
}
</style>
