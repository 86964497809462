<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-data-table
          disable-filtering
          disable-pagination
          disable-sort
          :items-per-page="100"
          hide-default-footer
          :items="hosts"
          :headers="hostsHeaders"
          @click:row="navigateToHostDetails"
          :loading="hostsLoading"
          loading-text=""
        >
          <template v-slot:top>
            <div class="pa-2">
              <v-btn tile class="primary" @click="getHosts(true)">
                <v-icon>
                  mdi-refresh
                </v-icon>
                <span>Refresh</span></v-btn
              >
            </div>
          </template>
          <template v-slot:item.statusTimestamp="{ item }">
            {{ item.statusTimestamp | formatDate }}
          </template>
          <template v-slot:progress>
            <div style="height:200px;position:relative;margin-top:4em;">
              <LoadingOverlay
                :absolute="true"
                :loading="hostsLoading"
                :loadingText="'Loading hosts...'"
              ></LoadingOverlay>
            </div>
          </template>
        </v-data-table>
        <router-view></router-view>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LoadingOverlay from "../../components/loading-overlay.vue";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      hostsHeaders: [
        {
          text: "Session host",
          value: "host",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Allow new session",
          value: "allowNewSession",
        },
        {
          text: "Status Timestamp",
          value: "statusTimestamp",
        },
      ],
    };
  },
  async created() {
    if (!this.hosts.length > 0) {
      await this.getHosts(true);
    }
  },
  methods: {
    ...mapActions("hosts", ["getHostsAsync"]),
    async getHosts(update = false) {
      await this.getHostsAsync(update);
    },
    navigateToHostDetails({ host }) {
      this.$router.push({
        path: `hosts/${host}`,
      });
    },
  },
  computed: {
    ...mapState("hosts", ["hostsLoading", "hosts"]),
  },
  components: { LoadingOverlay },
};
</script>
