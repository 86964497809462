import axios from "axios";
// import Vue from "vue";

const httpClient = axios.create({
  baseURL: "https://virtual-desktop-admin-api.azurewebsites.net/api/",
  //baseURL: "https://localhost:7057/api/",
});

httpClient.interceptors.request.use(
  async (config) => {
    // const token = await Vue.prototype.$MSAL.acquireToken();
    // config.headers.Authorization = "Bearer " + token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

httpClient.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    console.log(error.response);
    return Promise.reject(error);
  }
);

export default httpClient;
