import * as msal from "msal";

export default class MSAL {
  constructor() {
    this.config = {
      auth: {
        clientId: "7e5c4cab-358f-4cf7-bbb3-4beecc1d64a6",
        authority:
          "https://login.microsoftonline.com/968e27e6-1193-4212-992c-b00bfe88578d/",
        redirectUri: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
      },
    };
    this.msalInstance = new msal.UserAgentApplication(this.config);
    this.msalInstance.handleRedirectCallback(() => {});

    this.scopes = {
      scopes: [
        "api://418002e1-e539-46b7-89c3-f1b092e53223/virtual_desktop_contributor",
      ],
    };
  }

  async login() {
    if (this.msalInstance.getAccount()) {
      const response = await this.msalInstance.acquireTokenSilent(this.scopes);
      return response.accessToken;
    } else {
      await this.msalInstance.loginPopup(this.scopes);
    }
  }

  logout() {
    this.msalInstance.logout();
  }

  getAccount() {
    return this.msalInstance.getAccount();
  }

  async acquireToken() {
    try {
      const response = await this.msalInstance.acquireTokenSilent(this.scopes);
      return response.accessToken;
    } catch (error) {
      alert("Error code: " + error.errorCode);
      if (
        error.errorCode === "consent_required" ||
        error.errorCode === "interaction_required" ||
        error.errorCode === "login_required"
      ) {
        const response = await this.msalInstance.acquireTokenPopup(this.scopes);
        return response.accessToken;
      } else if (error.errorCode === "token_renewal_error") {
        const response = await this.msalInstance.acquireTokenSilent(
          this.scopes
        );
        return response.accessToken;
      }
    }
  }
}
